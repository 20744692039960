import './App.css';
import Repository from './Repository';
import ResizableContainer from './ResizableContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { SingleFrameWall } from './SingleFrameWall';

/**
 * Collection is where viewers see art work. 
 * It manages what is presented upon entry and how viewers navigate.
 */
function Collection() {
  const id = useParams().id;
  const navigate = useNavigate();
  const [pos, setPos] = useState(Repository.count() - 1);
  const [asset, setAsset] = useState();

  useEffect(() => {
    if (!id) {
      setAsset(Repository.getByIndex(pos));
    } else {
      let a = Repository.getById(id);
      setAsset(a);
      setPos(a.index);
    }
  }, [id, asset, pos]);

  const next = useCallback(() => {
    let p = pos - 1
    if (p < 0) {
      p = Repository.count() - 1;
    }
    const n = Repository.getByIndex(p);
    setPos(p);
    navigate(`/collection/home/${n.id}`);
  }, [pos, navigate]);

  const prev = useCallback(() => {
    let p = pos + 1
    if (p === Repository.count()) {
      p = 0;
    }
    const n = Repository.getByIndex(p);
    setPos(p);
    navigate(`/collection/home/${n.id}`);
  }, [pos, navigate]);

  return (
    <ResizableContainer>
      <SingleFrameWall asset={asset} onNext={next} onPrev={prev} />
    </ResizableContainer>
  );
}

export default Collection;

import './App.css';
import { useState } from 'react';

function Frame(props) {
    const fs = props.frameStyle;
    let [imageLoaded, setImageLoaded] = useState(false);

    let borderSize = (fs.borderWidth + fs.padding + fs.imageBorderWidth) * 2;
    let maxImageWidth = props.maxWidth - borderSize;
    let maxImageHeight = props.maxHeight - borderSize;
    let imageWidth = 0;
    let imageHeight = 0;
    if (props.imageWidth > props.imageHeight) {
        imageWidth = maxImageWidth;
        imageWidth = imageWidth - (imageWidth % 3);
        imageHeight = (imageWidth / 3) * 2;
        if (imageHeight > maxImageHeight) {
            imageHeight = maxImageHeight - (maxImageHeight % 2) ;
            imageWidth = (imageHeight / 2) * 3;
        }
    } else {
        imageHeight = maxImageHeight
        imageHeight = imageHeight - (imageHeight % 3);
        imageWidth = (imageHeight / 3) * 2;
        if (imageWidth > maxImageWidth) {
            imageWidth = maxImageWidth - (maxImageWidth % 2);
            imageHeight = (imageWidth / 2) * 3;
        }
    }

    function handleOnLoad() {
        setImageLoaded(true);
    }

    return (
        <div style={
            {
                margin: 'auto',
                display: 'block',
                width: `${props.maxWidth}px`,
                position: 'relative'
            }}>
            <div style={{
                display: 'block',
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
                margin: 'auto',
                boxShadow: '0px 20px 30px 10px rgba(0,0,0,0.3)',
                borderWidth: `${fs.borderWidth}px`,
                borderStyle: `${fs.borderStyle}`, 
                borderColor: `${fs.borderColor}`,
                backgroundColor: `${fs.backgroundColor}`,
                borderRadius: `${fs.borderRadius}`,
                padding: `${fs.padding}px`

            }}>
                <img src={`${props.src}`} alt="" style={
                    {
                        display: 'block',
                        width: `${imageWidth}px`,
                        height: `${imageHeight}px`,
                        opacity: imageLoaded ? 1 : 0,
                        transition: 'opacity 0.6s ease-in',
                        borderWidth: `${fs.imageBorderWidth}px`,
                        borderColor: `${fs.imageBorderColor}`,
                        borderStyle: `${fs.imageBorderStyle}`
                    }} onLoad={handleOnLoad} />
            </div>
        </div>
    );
}

export default Frame;

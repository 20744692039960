
const FrameStyle = {
    default: {
        borderWidth: 20,
        padding: 20,
        borderStyle: 'solid',
        borderColor: '#2F2D2D #434040 #4F4C4C #434040',
        backgroundColor: '#F5F5F5',
        borderRadius: '2px',
        imageBorderWidth: 2,
        imageBorderColor: '#BBBAB4 #C7C7BF #E5E4DF #C7C7BF',
        imageBorderStyle: 'solid'
    }
};

export default FrameStyle;

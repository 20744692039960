import { useState, useEffect, Children, isValidElement, cloneElement } from 'react';

function getSize() {
  return { width: window.innerWidth, height: window.innerHeight };
}

/**
 * ResizableContainer detects current viewport size
 * and pass it on to a child element.
 */
function ResizableContainer({ children }) {
  const [size, setSize] = useState(getSize());
  if (children.length > 1) {
    throw new Error("ResizableContainer can only contain a single element");
  }
  useEffect(() => {
    function onResize() {
      setSize(getSize());
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const childWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { width: size.width, height: size.height });
    }
    return child;
  });

  return <div>{childWithProps}</div>;
}

export default ResizableContainer;

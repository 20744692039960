import Frame from './Frame';
import FrameStyle from './FrameStyle';
import { InfoPanel } from './InfoPanel';



/**
 * SingleFrameWall handles the geometry for display one
 * artwork utilising all the space available.
 */
export function SingleFrameWall(props) {
  const asset = props.asset;
  const displayAreaWidth = Math.floor(props.width * 0.95);
  const paddingTop = Math.floor(props.height * 0.05);
  const artWorkHeight = Math.floor(props.height / 1.618) - paddingTop;
  const infoPanelMaxHeight = props.height - (artWorkHeight + paddingTop);

  if (asset) {
    return (
      <div key={asset.id} style={{width: `${props.width}px`, height: `${props.height}px`}}>
        <div  style={{ display: 'block', margin: 'auto', width: `${displayAreaWidth}px`, paddingTop: `${paddingTop}px` }}>
          <Frame src={`${process.env.PUBLIC_URL}/assets/${asset.id}/${asset.assetId}`} imageWidth={asset.width} imageHeight={asset.height} frameStyle={FrameStyle.default} maxWidth={displayAreaWidth} maxHeight={artWorkHeight} />
          <InfoPanel asset={asset} width={props.width} height={infoPanelMaxHeight} onNext={props.onNext} onPrev={props.onPrev} />
        </div>
      </div>
    );
  }
  return null;
}

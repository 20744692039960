class Theme {
    static FontHeading = "'PT Serif', serif";
    static FontBody = "'PT Serif', serif";
    static Color1 = "#F2F2F2";
    static Color2 = "#333333";
    static Color3 = "#0D0D0D"
    static ColorFinePrint = '#4D5159'
};

export default Theme;

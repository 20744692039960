import Theme from './Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import { faCircleInfo, faChevronRight, faChevronLeft, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

fontawesome.library.add(faCircleInfo, faChevronLeft, faChevronRight, faCircleXmark);

export function InfoPanel(props) {
    const marginTop = 40;
    let asset = props.asset;

    return (
        <div style={{ marginTop: `${marginTop}px`, textAlign: 'center', verticalAlign: 'baseline' }}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '2em', color: Theme.ColorFinePrint }}>
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" style={{ cursor: 'pointer' }} onClick={props.onPrev} />
            </div>
            <div style={{
                width: `${props.width / 1.6}px`,
                color: Theme.Color2,
                display: 'inline-block',
                verticalAlign: 'middle'
            }}>
                <div style={{ width: '100%' }}>
                    <h1 style={{ fontFamily: Theme.FontHeading, fontSize: '1em', fontWeight: 'normal', fontStyle: 'italic', marginBottom: '0.5em' }}>{asset.title}</h1>
                    <p style={{ fontFamily: Theme.FontBody, fontSize: '0.9em', marginTop: '0em', textTransform: 'lowercase' }}>{asset.description}</p>
                    <div style={{ color: Theme.ColorFinePrint, fontSize: '0.8em', marginBottom: '0.1em' }}>
                        <div style={{ fontFamily: Theme.FontBody }}>{asset.setup}</div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '2em', color: Theme.ColorFinePrint }}>
                <FontAwesomeIcon icon="fa-solid fa-chevron-right" style={{ cursor: 'pointer' }} onClick={props.onNext} />
            </div>
        </div>
    );
}

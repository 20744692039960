class Repository {
    constructor() {
        this.items = [
            {
                id: "sydney-harbour-bridge",
                title: "Sydney Harbour Bridge",
                assetId: "IMG_3328.jpg",
                timestamp: 1673721043,
                description: `The price for getting up at 4am to drive down to Olympic Drive in Kirribilli`,
                setup: `Shot in the civil twilight with an ND8 filter`,
                aperture: 8,
                shutterSpeed: '20',
                exposureCompensation: 0,
                focalLength: 25,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "frangipani-flower",
                title: "Frangipani Flower",
                assetId: "IMG_3171.jpg",
                timestamp: 1683008693,
                description: `Sign of summer down under`,
                setup: `Handheld shot in a cloudy evening`,
                aperture: 1.2,
                shutterSpeed: '1/200',
                exposureCompensation: +1,
                focalLength: 85,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "lego-optimus-prime",
                title: "Lego Optimus Prime",
                assetId: "_MG_3759.jpg",
                timestamp: 1674778680,
                description: `A gift from my son`,
                setup: `Shot with two off camera speedlites, with a blue gel on one to create the background`,
                aperture: 8,
                shutterSpeed: '1/180',
                exposureCompensation: 0,
                focalLength: 105,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "ephemeral-life",
                title: "Life",
                assetId: "IMG_3978.jpg",
                timestamp: 1676176581,
                description: `Is an ephemeral beauty`,
                setup: `Shot in bright day light with a CPL filter`,
                aperture: 8,
                shutterSpeed: '1/80',
                exposureCompensation: 0,
                focalLength: 35,
                iso: 200,
                width: 1200,
                height: 800
            },
            {
                id: "toto-sunday-afternoon-nap",
                title: "Sunday Afternoon",
                assetId: "IMG_3841.jpg",
                timestamp: 1675580543,
                description: `No time to play`,
                setup: `Handheld under low light condition`,
                aperture: 4,
                shutterSpeed: '1/25',
                exposureCompensation: 0,
                focalLength: 96,
                iso: 3200,
                width: 1200,
                height: 800
            },
            {
                id: "follow-me-stairs",
                title: "Stairs",
                assetId: "IMG_3921.jpg",
                timestamp: 1675807810,
                description: `Follow me`,
                setup: `Handheld in natural morning light, cinematic filter applied in post`,
                aperture: 8,
                shutterSpeed: '1/30',
                exposureCompensation: 0,
                focalLength: 24,
                iso: 800,
                width: 1200,
                height: 800
            },
            {
                id: "iron-man",
                title: "Iron Man",
                assetId: "_MG_3775.jpg",
                timestamp: 1674751081,
                description: `My favorite superhero`,
                setup: `Shot with two off camera speedlites, with a yellow and brown gel mix on one to create the background`,
                aperture: 8,
                shutterSpeed: '1/180',
                exposureCompensation: 0,
                focalLength: 105,
                iso: 100,
                width: 800,
                height: 1200
            },
            {
                id: "calm-before-storm",
                title: "Calm before storm",
                assetId: "IMG_4047.jpg",
                timestamp: 1676700707,
                description: `Standing in the middle of a golf course just moments before the storm definitely was not wise. Although, as it turns out, risk worth the reward.`,
                setup: `Handheld shot on a cloudy day`,
                aperture: 8,
                shutterSpeed: '1/125',
                exposureCompensation: 0,
                focalLength: 105,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "english-box",
                title: "English Box",
                assetId: "IMG_4200.jpg",
                timestamp: 1677969542,
                description: ``,
                setup: `Camera mounted on a tripod, just a few inches away from the subject`,
                aperture: 4,
                shutterSpeed: '1/320',
                exposureCompensation: 0,
                focalLength: 105,
                iso: 400,
                width: 1200,
                height: 800
            },
            {
                id: "the-waiting",
                title: "The Waiting",
                assetId: "_MG_6358.jpg",
                timestamp: 1684134000,
                description: ``,
                setup: ``,
                aperture: 1.2,
                shutterSpeed: '1/1000',
                exposureCompensation: 0,
                focalLength: 85,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "a-rare-meeting",
                title: "A Rare Meeting",
                assetId: "_MG_6340.jpg",
                timestamp: 1684134000,
                description: `I'm so glad that I caught this even though the focus is a bit off.`,
                setup: ``,
                aperture: 1.2,
                shutterSpeed: '1/2000',
                exposureCompensation: 0,
                focalLength: 85,
                iso: 100,
                width: 1200,
                height: 800
            },
            {
                id: "the-little-fiddle",
                title: "The Little Fiddle",
                assetId: "IMG_4263.jpg",
                timestamp: 1678295220,
                description: `Irish Pub in Chirstchurch`,
                setup: ``,
                aperture: 4,
                shutterSpeed: '1/50',
                exposureCompensation: 0,
                focalLength: 35,
                iso: 3200,
                width: 1200,
                height: 800
            }
        ];
    }

    getByIndex(pos) {
        const i = this.items[pos];
        i.index = pos;
        return i;
    }

    getById(id) {
        for (const [idx, item] of this.items.entries()) {
            if (item.id === id) {
                item.index = idx;
                return item;
            }
        }
        return null;
    }

    count() {
        return this.items.length;
    }
};

let defaultRepository = new Repository();
export default defaultRepository;